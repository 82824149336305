/* eslint-disable no-useless-computed-key */

export const BASE_TOKENS = {
    pink: {
        [50]: '#FDF2F8',
        [100]: '#FCE7F3',
        [300]: '#F9A8D4',
        [900]: '#831843',
    },
    purple: {
        [50]: '#FAF5FF',
        [100]: '#F3E8FF',
        [300]: '#D8B4FE',
        [400]: '#C084FC',
        [900]: '#581C87',
    },
    blue: {
        [50]: '#EFF6FF',
        [100]: '#DBEAFE',
        [200]: '#BFDBFE',
        [300]: '#93C5FD',
        [400]: '#60A5FA',
        [500]: '#3B82F6',
        [600]: '#2563EB',
        [700]: '#1D4ED8',
        [800]: '#1E40AF',
        [900]: '#1E3A8A',
    },
    orange: {
        [50]: '#FFF7ED',
        [100]: '#FFEDD5',
        [200]: '#FED7AA',
        [300]: '#FDBA74',
        [400]: '#FB923C',
        [500]: '#F59E0B',
        [600]: '#EA580C',
        [700]: '#C2410C',
        [900]: '#7C2D12',
    },
    red: {
        [50]: '#FEF2F2',
        [100]: '#FEE2E2',
        [300]: '#FCA5A5',
        [500]: '#EF4444',
        [600]: '#DC2626',
        [700]: '#B91C1C',
        [900]: '#7F1D1D',
    },
    grey: {
        [0]: '#FFFFFF',
        [50]: '#F9FAFB',
        [100]: '#F3F4F6',
        [200]: '#e5e7eb',
        [300]: '#D1D5DB',
        [400]: '#9CA3AF',
        [500]: '#6B7280',
        [600]: '#4B5563',
        [700]: '#374151',
        [800]: '#1F2937',
        [900]: '#111827',
        [950]: '#030712',
    },
    slate: {
        [50]: '#F8FAFC',
        [100]: '#F1F5F9',
        [200]: '#E2E8F0',
    },
    green: {
        [50]: '#F0FDF4',
        [100]: '#DCFCE7',
        [300]: '#86EFAC',
        [400]: '#4ADE80',
        [500]: '#22C55E',
        [600]: '#16A34A',
        [700]: '#15803D',
        [800]: '#166534',
        [900]: '#14532D',
    },
    cyan: {
        [100]: '#CFFAFE',
        [300]: '#67E8F9',
        [400]: '#22D3EE',
        [900]: '#164E63',
    },
    yellow: {
        [100]: '#FEF9C3',
        [400]: '#FACC15',
        [300]: '#FDE047',
        [900]: '#713F12',
    },
    emerald: {
        [700]: '#047857',
    },
} as const

export const DESIGN_TOKENS = {
    muted: '#F4F4F5',
    background: BASE_TOKENS.grey[0],
    foreground: '#09090B',
    mutedForeground: '#71717A',
    primaryForeground: '#FAFAFA',
    secondaryForeground: '#18181B',
    input: '#E4E4E7',
    error: BASE_TOKENS.red[500],

    accent: BASE_TOKENS.slate[100],
    accentForeground: '#0F172A',
    accentGreyDark: '#DBE1E9',

    text: {
        textAccentForeground: '#F97316',
        textForeground: '#09090B',
        textMutedForeground: '#71717A',
        textError: BASE_TOKENS.red[500],
    },

    primary: {
        brand: '#1C1C1C',
    },

    border: {
        light: '#E4E4E7',
        borderInput: '#E4E4E7',
        borderPrimary: '#18181B',
    },

    boxShadow: {
        inputOutline: `0 0 0 0.2rem ${BASE_TOKENS.grey[0]},
            0 0 0 0.4rem ${BASE_TOKENS.grey[400]}`,
        button: '0rem 0.2rem 0.4rem 0rem rgba(0, 0, 0, 0.08)',
        card: '0rem 0.2rem 0.4rem 0rem rgba(0, 0, 0, 0.08), 0px 3px 10px 0px rgba(0, 0, 0, 0.1)',
    },

    borderRadius: {
        sm: '0.4rem',
        md: '0.6rem',
        lg: '0.8rem',
        round: '100%',
    },

    bg: {
        map: 'rgba(53, 53, 53)',
    },

    wilson: {
        blue: '#0b2b40',
    },
} as const
