import styled from '@emotion/styled'
import { DESIGN_TOKENS } from 'constants/color.constants'

const StyledRequiredInputLabel = styled.span`
    color: ${DESIGN_TOKENS.text.textAccentForeground};
`

export const RequiredInputLabel = () => {
    return <StyledRequiredInputLabel> *</StyledRequiredInputLabel>
}
